.Card_card__tX2Ix {

    display: flex;

    flex-direction: column
}

  .Card_card__tX2Ix .Card_image__edrIj {

    position: relative;

    margin-bottom: 0.5rem;

    height: 0px;

    width: 100%;

    border-radius: 0.25rem;

    padding-top: 64%
}

  /* Aspect ratio box - https://css-tricks.com/aspect-ratio-boxes */

  .Card_card__tX2Ix .Card_image__edrIj img {

    position: absolute;

    top: 0px;

    left: 0px;

    height: 100%;

    width: 100%;

    border-radius: 0.25rem;

    -o-object-fit: cover;

       object-fit: cover
}

  .Card_card__tX2Ix .Card_content__HMpqQ .Card_meta__PvgBe {

    margin-bottom: 0.5rem;

    font-size: 0.875rem;

    line-height: 1.25rem
}

  .Card_card__tX2Ix .Card_content__HMpqQ .Card_title__IijWS {

    margin-bottom: 0.5rem;

    font-weight: 700
}

  .Card_card__tX2Ix .Card_content__HMpqQ .Card_body__eda2c {

    margin-bottom: 0.5rem
}

  .Card_card__tX2Ix .Card_footer__x7j5N {

    margin-top: auto;

    display: flex;

    align-items: center;

    justify-content: space-between
}

  .Card_card__tX2Ix .Card_footer__x7j5N .Card_timestamp__UAog8 {

    font-size: 0.875rem;

    line-height: 1.25rem
}

